/**
 * Basic page layout-related classes and convenience classes.
 */
@use 'respond-to';
@use 'clearfix';
@use 'color';
@use 'container';
@use 'variables';

// Media: small screens and larger
@include respond-to.respond-to {
  // "container" containers that span the width of the viewport
  .area--dark {
    background: #111111;
  }

  .area--shaded {
    background: #f3f3f3;
  }

  // Container for general site contents
  .page {
    @include clearfix.clearfix;
    background: variables.$main-bg;
  }

  /*
   * Avoid using this; favor semantic approaches.
   * But sometimes nothing beats a good clearfix
   */
  .clearfix {
    @include clearfix.clearfix;
  }

  // Convenience method to limit the length of text blocks
  .copy {
    max-width: 62em;
  }

  // Individual row of content, used as a wrapper
  .container {
    @include container.container;
    padding: 0 variables.$grid-margin-base;
    position: relative;
  }

  // Generic image alignment classes
  .image {
    margin-bottom: 10px;
    max-width: 100%;
  }

  /*
   * Reverses text colors
   * See also the color mixin!
   */
  .inverse {
    color: color.invertColor(variables.$text-color);
  }

  // Convenience class for hiding elements
  .hidden {
    display: none;
  }

  // Attempt to break up long words, where supported
  .hyphens {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .invisible {
    visibility: hidden;
  }

  /*
   * Convenience class to remove bottom margins (e.g. paragraphs)
   * in modules, with allowance for reasonably deep nesting
   */
  .module > *:last-child,
  .module > *:last-child > *:last-child,
  .module > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
  }

  // Control text alignment on page
  .text-center {
    text-align: center;
  }

  .text-justify {
    text-align: justify;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .relative {
    position: relative;
  }

  // Convenience class for text that's exclusively for screen readers
  .screen-reader-text {
    left: -9999px;
    position: absolute;
    top: -9999px;
  }

  /*
   * Semantic list / secret list - used when a <ul> or <ol>
   * semantically applies but a visual reset is needed
   */
  .sl {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .sb {
    color: currentColor;
    background: transparent;
    border: 0;
    padding: 0;
  }

  .sa {
    color: currentColor;
    text-decoration: none;

    &:hover,
    &:visited,
    &:active {
      color: currentColor;
    }
  }

  /*
   * Pretty list - used on a parent <ul> or <ol> to reduce the
   * indentation used on some browsers
   */
  .pl {
    list-style-position: inside;
    margin-left: 8px;
    padding-left: 1em;
    text-indent: -1em;

    li {
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  // Definition list in a "natural" style (horizontal floating)
  .dl--n {
    display: -webkit-flex; // Safari & Mobile Safari
    display: flex;

    dt {
      float: left;
      padding-right: 0.8ex;
    }

    dd {
      -webkit-flex: 1 2 50%; // Safari & Mobile Safari
      flex: 1 2 50%;
      margin: 0;
    }
  }

  /*
   * Hack to make sure spinjs spinners always show in center of
   * the screen.
   */
  .spinner--centered {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
  }

  /*
   * Convenience classes to target styles only when
   * the user is logged in or out
   */
  .loggedout .loggedin-only {
    display: none;
  }

  .loggedin .loggedout-only {
    display: none;
  }

  .show-xs,
  .show-sm,
  .show-lg,
  .show-xl {
    display: none;
  }

  /**
  Convenience classes to display elements depending
  on SVG support
  */
  .svg .if-no-svg {
    display: none;
  }
  .no-svg .if-svg {
    display: none;
  }

  /*
   * Vertical centering; note that vc__wrap should be applied as
   * a wrapper to the item being centered!
   */
  .vc {
    display: table;
    height: 100%;
    width: 100%;
  }

  .vc__wrap {
    display: table-cell;
    vertical-align: middle;
  }

  // Vertical padding
  .pad1,
  .pad2,
  .pad3 {
    padding: 20px 0;
  }

  .pad4 {
    padding: 10px 0;
  }

  .pad5 {
    padding: 5px 0;
  }

  // Horizontal padding
  .hpad1,
  .hpad2,
  .hpad3 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .hpad4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .hpad5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .fallback .hide-on-fallback {
    display: none;
  }
}

@include respond-to.respond-to(480px) {
  .hide-xs {
    display: none;
  }

  .show-xs {
    display: block;
  }
}

@include respond-to.respond-to(600px) {
  .image--left,
  .image--right {
    max-width: 40%;
  }

  .image--left {
    float: left;
    margin-right: 20px;
  }

  .image--right {
    float: right;
    margin-left: 20px;
  }
}

@include respond-to.respond-to(768px) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: block;
  }
}

@include respond-to.respond-to(1024px) {
  .hide-lg {
    display: none;
  }

  .show-lg {
    display: block;
  }
}

@include respond-to.respond-to(1200px) {
  .container {
    padding: 0 variables.$grid-margin-large;
  }

  .hide-xl {
    display: none;
  }

  .show-xl {
    display: block;
  }

  // Vertical padding
  .pad1 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pad2 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .hpad1 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .hpad2 {
    padding-left: 30px;
    padding-right: 30px;
  }
}
