@use 'sass:math';
@use 'sass:string';
/**
 * An opacity fix that's friendly with Old IE.
 *
 * Please use sparingly; this adds a truly NASTY amount of bloat!
 */

@mixin opacity($opacity) {
  @if $opacity == 1 {
    filter: string.unquote(
      'progid:DXImageTransform.Microsoft.Alpha(enabled=false)'
    );
  } @else {
    filter: string.unquote(
      'progid:DXImageTransform.Microsoft.Alpha(Opacity=#{math.round($opacity * 100)})'
    );
  }

  opacity: $opacity;
}
