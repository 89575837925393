@use 'color';
@use 'exports';
@use 'respond-to';
@use 'variables';

/**
 * Styles for a sidebar submenu.
 */

@include exports.exports('submenu') {
  .submenu {
    margin-bottom: 20px;
    @include respond-to.respond-to(1024px) {
      padding-right: 40px;
    }
  }

  .submenu__list {
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .submenu__heading,
  .submenu__item {
    border-bottom: 1px dotted #b2b2b2;
    padding: 10px 0;

    &:first-child {
      padding-top: 0;
    }

    .submenu__item {
      border-bottom: 0;
      padding: 0;
      position: relative;

      &:last-child {
        border-bottom: none;
      }

      .submenu__link {
        font-weight: variables.$neue-roman;
      }
    }
  }

  .submenu__heading {
    margin-bottom: 10px;
    @include respond-to.respond-to(1024px) {
      display: none;
    }
  }

  .submenu__heading--usable {
    @include respond-to.respond-to(1024px) {
      display: block;
    }
  }

  .submenu__heading--active .submenu__link {
    @include color.color(variables.$ted-red);
  }

  .submenu__link {
    line-height: 1;
    //Annoying, but fastest way at the time to prevent global $_a selector from being more specific
    text-decoration: none !important;
  }

  .submenu__item--active {
    & > .submenu__link {
      @include color.color(variables.$ted-red);
    }
  }

  .submenu__item__count {
    position: absolute;
    right: 0;
    padding: 7px 8px 7px 0;
    top: 0;
    line-height: 2;
    color: variables.$gray;
    @include respond-to.respond-to(1024px) {
      position: static;
      float: right;
      padding: 0;
      line-height: 2;
      color: variables.$gray;
    }
  }

  // Only display the submenu on mobile if JS is unavailable
  .js .submenu {
    display: none;
    @include respond-to.respond-to(1024px) {
      display: block;
    }
  }

  // Dashboard submenu overrides
  .dashboard-submenu {
    .submenu__item {
      border-bottom: 0;

      &:not(:last-child) {
        border-bottom: 1px solid #ececec;
      }
    }
  }
}
