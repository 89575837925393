@use 'exports';
@use 'respond-to';
@use 'opacity';
@use 'inline-block';
@use 'icon';
@use 'variables';

/**
 * Button styles
 */

$button-height: 40px;
$button-border-width: 1px;
$button-vertical-padding: 7px;
$button-label-gradient: linear-gradient(
  to left,
  rgba(0, 0, 0, 0.2) 38px,
  rgba(0, 0, 0, 0) 38px
);
$button-label-gradient-right: linear-gradient(
  to right,
  rgba(0, 0, 0, 0.2) 38px,
  rgba(0, 0, 0, 0) 38px
);

@include exports.exports('button') {
  @include respond-to.respond-to {
    .button {
      background-color: #f5f5f5;
      background-image: linear-gradient(#f5f5f5, #e6e6e6);
      border-radius: 3px;
      @include inline-block.inline-block;
      border: $button-border-width solid #cccccc;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      line-height: $button-height - $button-vertical-padding * 2 -
        $button-border-width * 2;
      padding: $button-vertical-padding 30px;
      text-decoration: none;
      text-shadow: 0 1px 0 white;

      &:hover {
        background: #eeeeee linear-gradient(#eeeeee, #dddddd);
      }

      &:hover:disabled {
        background: #f5f5f5 linear-gradient(#f5f5f5, #e6e6e6);
      }
    }

    .button,
    .inverse .button,
    .inverse .button:hover {
      color: #444444;
    }

    .button[disabled],
    .button--disabled {
      @include opacity.opacity(0.6);
    }

    .button--medium {
      background: #ececec linear-gradient(#ececec, #dbdbdb);
      border: $button-border-width solid #bbbbbb;
      color: #333333;

      &:hover {
        background: #e3e3e3 linear-gradient(#e3e3e3, #d4d4d4);
        color: #111111;
      }

      &:hover:disabled {
        background: #ececec linear-gradient(#ececec, #dbdbdb);
      }
    }

    .button--small {
      padding: 2px 20px;
    }

    .button--blue {
      background: #009fe8 linear-gradient(#009fe8, #0090d3);
      border: 0;
      color: white;
      text-shadow: none;

      &:hover {
        background: #02adfe linear-gradient(#02adfe, #029de7);
      }

      &:hover:disabled {
        background: #009fe8 linear-gradient(#009fe8, #0090d3);
      }

      .inverse &,
      .inverse &:hover {
        color: #ffffff;
      }
    }

    .button--red {
      background: #bb0000 linear-gradient(#dd0000, #bb0000);
      border-color: transparent;
      color: #ffffff;
      font-weight: bold;
      text-shadow: 0 1px 0 #111111;

      &:hover {
        background: #cc0000 linear-gradient(#ee0000, #cc0000);
        color: #ffffff; // override anchor element hover color
      }

      &:hover:disabled {
        background: #bb0000 linear-gradient(#dd0000, #bb0000);
      }

      .inverse &,
      .inverse &:hover {
        color: #ffffff;
      }
    }

    .button--explore {
      background: #333333;
      border: none;
      color: #ffffff;
      font-weight: bold;
      text-shadow: none;

      &:hover {
        background: #000000;
        color: #ffffff; // override anchor element hover color
      }

      &:hover:disabled {
        background: #bb0000 linear-gradient(#dd0000, #bb0000);
      }
    }

    .button--dark,
    .button--highlight {
      background: #555555 linear-gradient(#555555, #222222);
      border-color: transparent;
      color: #ffffff;
      font-weight: bold;
      text-shadow: 0 1px 0 #111111;

      &:hover {
        background: #666666 linear-gradient(#666666, #2d2d2d);
        color: #ffffff; // override anchor element hover color
      }

      &:hover:disabled {
        background: #555555 linear-gradient(#555555, #222222);
      }

      .inverse &,
      .inverse &:hover {
        color: #ffffff;
      }
    }

    .button--labeled {
      background-image:
        $button-label-gradient, linear-gradient(#666666, #4d4d4d);
      overflow: hidden;
      padding: $button-vertical-padding 8px;

      &:hover {
        background-image:
          $button-label-gradient, linear-gradient(#5e5e5e, #4f4f4f);
      }

      &:active {
        background-image: $button-label-gradient, #555555;
      }
    }

    .button__icon {
      @include icon.icon-size(22px);
      float: left;
      position: relative;
      margin-right: 15px;
    }

    .button__label {
      display: block;
      overflow: hidden;
    }

    .button--facebook {
      border-color: #3e5b96;
      background:
        #3e5b96 $button-label-gradient-right,
        linear-gradient(#4a6cb0, #3f5c97);

      &:hover {
        background-image:
          $button-label-gradient, linear-gradient(#4366ad, #3a598f);
      }

      &:hover:disabled {
        background-image:
          $button-label-gradient, linear-gradient(#4a6cb0, #3f5c97);
      }
    }

    .button--highlight {
      background: variables.$ted-red-grad;
      padding: $button-vertical-padding 30px;

      &:hover {
        background: variables.$ted-red;
      }

      &:hover:disabled {
        background-image: variables.$ted-red-grad;
      }
    }

    .button--dark,
    .button--highlight,
    .button--facebook {
      &[disabled],
      &.button--disabled {
        @include opacity.opacity(0.2);
      }
    }

    .button--link {
      background: transparent;
      border: none;
      text-decoration: underline;
      text-shadow: none;

      &:hover {
        background: transparent;
      }

      &:hover:disabled {
        background: transparent;
      }
    }

    .button--narrow,
    .button--small {
      padding-left: 10px;
      padding-right: 10px;
    }

    .button--midsize,
    .button--wide {
      display: block;
      margin: 0 auto;
      max-width: 12em;
      text-align: center;
      width: 100%;
    }

    .button--wide {
      max-width: 50em;
    }
  }
  @include respond-to.respond-to(768px) {
    .button--small {
      font-size: 13px;
    }
  }
}
