@use 'exports';
@use 'respond-to';
@use 'clearfix';

/**
 * Media object
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
 */

$media-image-margin-h: 20px;
$media-image-margin-v: 10px;

@mixin media-image-behavior($is-on) {
  .media__image {
    float: if($is-on, left, none);
    margin-bottom: if($is-on, 0, $media-image-margin-v);
    margin-right: if($is-on, $media-image-margin-h, 0);
    @if $is-on {
      // Special horizontal mode instructions
      width: auto;
    } @else {
      // Image width doesn't matter as much in vertical mode
      width: 100%;
    }
  }

  .media__image--alt {
    float: if($is-on, right, none);
    margin-bottom: if($is-on, 0, $media-image-margin-v);
    margin-left: if($is-on, $media-image-margin-h, 0);
    margin-right: 0;
  }

  .media__image--thumb {
    @if $is-on {
      width: 30%;
    }
  }
}

@include exports.exports('media') {
  @include respond-to.respond-to(320px) {
    .media {
      display: block; // in case applied to <a> etc.
      overflow: hidden;
      @include clearfix.clearfix;
    }

    .media__image {
      float: left;
      margin-right: $media-image-margin-h;
      text-align: center;
    }

    .media__image--alt {
      float: right;
      margin-left: $media-image-margin-h;
      margin-right: 0;
    }

    .media__image--thumb {
      width: 100px;
    }

    .media__message {
      word-wrap: break-word;
      overflow-wrap: break-word;
      overflow: hidden;
      @include clearfix.clearfix;
    }

    .media--oh-v {
      @include media-image-behavior(false);
    }
  }
  @include respond-to.respond-to(480px) {
    .media__image--thumb {
      width: 30%;
    }

    .media--xs-v {
      @include media-image-behavior(false);
    }

    .media--xs-h {
      @include media-image-behavior(true);
    }
  }
  @include respond-to.respond-to(768px) {
    .media--sm-v {
      @include media-image-behavior(false);
    }

    .media--sm-h {
      @include media-image-behavior(true);
    }
  }
  @include respond-to.respond-to(1024px) {
    .media--lg-v {
      @include media-image-behavior(false);
    }

    .media--lg-h {
      @include media-image-behavior(true);
    }
  }
}
