/**
 * CSS used by PagesController#show
 */

@use 'variables' with (
  $skip-globals: true,
  $skip-globals-complete: true
);
@use 'respond-to';
@use 'font-size';
@use 'icon';
@use 'button';
@use 'typography';
@use 'layout';
@use 'hero';
@use 'grid';
@use 'media';
@use 'thumb';
@use 'swipe';
@use 'submenu';
@use 'breadcrumb';
@use 'list';
@use 'clearfix';

@include respond-to.respond-to {
  .pages-status {
    border-radius: 5px;
    background-color: variables.$lighter-gray;
    padding: 20px;
    margin-bottom: 30px;
  }

  .pages-status--published {
    background-color: variables.$alert-success-bg;
  }

  .pages-status--unpublished {
    background-color: variables.$alert-warning-bg;
  }

  .pages-module {
    margin: 30px 0;
  }

  .pages-button {
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .pages-callout {
    background: variables.$darker-gray;
    padding: 20px;
    color: white;
  }

  .pages-carousel {
    background: #000000;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .pages-carousel__image {
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
    height: 0;
    padding-bottom: 56.25%;
    width: 100%;
  }

  .pages-carousel__content {
    padding: 20px 20px 10px;
  }

  .pages-carousel__text,
  .pages-carousel__credit {
    margin: 20px 0;
  }

  .pages-carousel__credit {
    @include font-size.font-size(12px);
  }

  .pages-carousel__context {
    font-weight: bold;
  }

  .pages-carousel__heading {
    @include font-size.font-line(28px, 30px);
    font-weight: variables.$neue-heavy;
    text-decoration: none;
  }

  .pages-carousel__flipper {
    display: none;
  }

  .pages-carousel__link {
    border: none;
  }

  .pages-carousel__nav {
    .page-nav__dot--active .page-nav__dot__link {
      color: #ffffff;
    }
  }

  .pages-copy--indented {
    padding-left: 2em;
  }

  .pages-copy--letter {
    padding-left: 1em;
    color: variables.$dark-gray;
  }

  .pages-featured-link {
    margin: 20px 0;
    text-decoration: none;

    &:hover {
      .pages-featured-link__icon {
        color: variables.$gray;
      }
    }
  }

  .pages-featured-link a {
    text-decoration: none;
  }

  .pages-featured-link__icon {
    @include icon.icon-size(30px);
    color: variables.$ted-red;
    margin-right: 10px;
  }

  .pages-giant-text {
    line-height: 1.3;
    @extend .p1;
  }

  .pages-image {
    margin: 20px 0;

    &.pages-image-float-left {
      max-width: 50%;
      margin-right: 1.4em;
      float: left;
    }

    &.pages-image-float-right {
      max-width: 50%;
      margin-left: 1.4em;
      float: right;
    }
  }

  .pages-image__content {
    display: block;
    max-width: 100%;
  }

  .pages-image__caption {
    margin-top: 5px;
  }

  .pages-quote {
    padding-left: 1em;
    margin: 0;
  }

  .pages-quote__source {
    font-style: italic;
  }

  .pages-hr {
    width: 100%;
    height: 1px;
    border: none;
    background: #ffffff;
    border-top: 2px solid variables.$light-gray;
    margin: 40px 0;
  }

  .pages-iframe {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }

  .pages-iframe__content {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .pages-promo {
    background-color: variables.$dark-gray;
    padding: 20px;
    color: white;
  }

  .pages-hero {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.38889;
  }

  .pages-json-debug {
    background-color: variables.$lighter-gray;
  }

  .pages-jwplayer__container {
    position: relative;
    width: 100%;
    height: 0;
  }

  .pages-jwplayer__player {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
  }

  .pages-placeholder {
    padding: 1em;
    background-color: variables.$light-gray;
  }

  .pages-footnote {
    font-size: 0.9em;
    color: variables.$gray;
  }

  .pages-tweets__tweet__image {
    margin-right: 10px;
    width: 48px;
  }

  .pages-tweets__tweet__meta,
  .pages-tweets__tweet__text {
    @include font-size.font-size(13px);
  }

  .pages-photo-feed {
    @include clearfix.clearfix;
  }

  .pages-photo-feed__list {
    overflow: hidden;
    width: 100%;
  }

  .pages-photo-feed__thumb {
    display: block;
    width: 33%;
    float: left;
    margin-right: 1px;
    margin-bottom: 1px;
  }

  .pages-photo-feed__link {
    clear: left;
    display: block;
  }

  .pages-share {
    margin: 20px 0;
  }

  .pages-video-embed__video {
    position: relative;

    height: 0;
    padding-bottom: 56.25%;
    width: 100%;
  }

  .pages-video-embed__video__object {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
  }

  /* styles for page builder email subscription block */
  .pages-newsletter-subscribe__heading {
    margin-bottom: 1em;
  }

  .pages-newsletter-subscribe__button {
    margin-bottom: 1em;
  }

  .pages-newsletter-subscribe__errors {
    color: #aa0000;
  }

  .pages-newsletter-subscribe__errors,
  .pages-newsletter-subscribe__success {
    display: none;
  }
}

@include respond-to.respond-to(768px) {
  .pages-carousel__heading {
    @include font-size.font-line(32px, 34px);
  }

  .pages-module {
    margin: 40px 0;
  }
}

@include respond-to.respond-to(1024px) {
  .pages-carousel__spacer {
    float: right;
    overflow: visible;
    pointer-events: none;
    width: 1%;
  }

  .pages-carousel__spacer__inner {
    height: 0;
    padding-bottom: 4200%;
    position: relative;
    width: 10000%;
  }

  .pages-carousel__image {
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 0;
    height: 100%;
    width: 75%;

    &::after {
      background-image: linear-gradient(
        to right,
        variables.$ninja-black,
        #000000
      );
      content: ' ';
      display: block;
      position: absolute;
      right: -1px;
      top: 0;
      width: 90%;
      height: 100%;
    }
  }

  .pages-carousel__content {
    padding: 20px 40px 50px 0;

    float: right;
    position: relative;
    left: 1%; // to compensate for the spacer
    top: 0;

    width: 60%;
  }

  .pages-carousel__flipper {
    color: variables.$light-gray;
    display: block;
    margin: 10px;
    position: absolute;
    margin-top: -10px;
    top: 50%;

    &:hover {
      color: variables.$white;
    }
  }

  .pages-carousel__flipper--prev {
    left: 0;
  }

  .pages-carousel__flipper--next {
    right: 0;
  }

  .pages-carousel__flipper__icon {
    @include icon.icon-size(34px);
  }

  .pages-carousel__nav {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .page-nav__next,
    .page-nav__prev {
      display: none;
    }
  }

  .pages-content {
    border-left: 1px solid #e4e4e4;
  }
}

@include respond-to.respond-to(1024px) {
  .pages-carousel__image {
    &::after {
      width: 60%;
    }
  }

  .pages-carousel__content {
    width: 50%;
  }
}

.pages-flex2 {
  flex: 2;
}

.icon {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 40px;
  height: 40px;
  line-height: 41px;
  font-size: 40px;

  transition: background-color 300ms;
  border-radius: 32px;
}

.icon-twitter {
  &:hover {
    background-color: #1da1f2;
  }
}

.icon-facebook {
  &:hover {
    background-color: #3467f6;
  }
}

.icon-email {
  &:hover {
    background-color: #c0c0c0;
  }
}

.icon-linkedin {
  &:hover {
    background-color: #2867b2;
  }
}

.Figure {
  display: table;
  margin-bottom: 10px;
  max-width: 100%;
  margin: 0;
}

.Figure__caption {
  color: #666;
  font-size: 13px;
  font-size: 0.8125rem;
  display: table-caption;
  caption-side: bottom;
}

.Figure__image {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  max-width: 100%;
}

.Figure__right {
  float: right;
  margin-left: 20px;
}

.Figure__left {
  float: left;
  margin-right: 20px;
}

.copy {
  max-width: 62em;
}

.media {
  display: block;
  overflow: hidden;
  @include clearfix.clearfix;
}

.pagesmodule {
  margin: 40px 0;
}

.markdownField {
  &:has(+ .markdownField) {
    margin-bottom: 0;
  }

  + .markdownField {
    margin: 0;
  }
}
