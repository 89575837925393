@use 'exports';
@use 'inline-block';
@use 'respond-to';

/**
 * Styles for SwipeJS-based slideshows/carousels.
 */

@include exports.exports('swipe') {
  @include respond-to.respond-to {
    .swipe {
      //visibility: hidden;
      position: relative;
    }

    .swipe__pages {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      white-space: nowrap;
    }

    .swipe__page {
      @include inline-block.inline-block;
      // overflow: hidden;
      flex-shrink: 0;
      white-space: normal;
      white-space-collapsing: discard;
      position: relative;
      width: 100%;
    }

    .swipe--initialized {
      .swipe__page {
        float: left;
      }
    }
  }
}
