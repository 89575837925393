@use 'exports';
@use 'font-size';
@use 'inline-block';
@use 'opacity';
@use 'respond-to';
@use 'variables';

/**
 * Styles for handling thumbnails; to be paired with the `thumb`
 * application helper.
 */

@include exports.exports('thumb') {
  @include respond-to.respond-to {
    .thumb {
      display: block;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .thumb__sizer {
      display: block;

      padding-bottom: 56.25%;
    }

    .thumb__tugger {
      text-align: center;
      white-space: nowrap;
      word-break: normal;
      position: absolute;
      bottom: -100px;
      top: -100px;
      left: 0;
      right: 0;
    }

    .thumb__image {
      @include inline-block.inline-block;
      vertical-align: middle;
      margin: 0;
      width: 100%;
    }

    .thumb__aligner {
      @include inline-block.inline-block;
      vertical-align: middle;
      height: 100%;
    }

    .thumb__duration {
      @include font-size.font-size(11px);

      background: variables.$natural-black;
      color: variables.$white;
      display: block;
      line-height: 1.2;
      @include opacity.opacity(0.8);
      padding: 2px 3px 2px;

      position: absolute;
      bottom: 3px;
      right: 3px;
    }

    .thumb--square {
      .thumb__sizer {
        padding-bottom: 100%;
      }

      .thumb__tugger {
        bottom: 0;
        top: 0;
        left: -100px;
        right: -100px;
      }

      .thumb__image {
        height: 100%;
        width: auto;
      }
    }

    .thumb--tv {
      .thumb__sizer {
        padding-bottom: 75%;
      }
    }

    .thumb--crop-top {
      .thumb__tugger {
        top: 0;
      }

      .thumb__aligner {
        display: none;
      }
    }

    .thumb--autoplay {
      &::after {
        transition:
          opacity 0.3s,
          transform 0.4s;
        @include opacity.opacity(0.8);
        transform: scale(0.8);
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 512 512'><defs><filter id='blur'><feGaussianBlur in='SourceGraphic' stdDeviation='20'/></filter><g id='play'><path d='M255.99 12C121.247 12 12 121.247 12 256s109.247 244 243.99 244C390.753 500 500 390.753 500 256S390.753 12 255.99 12zm0 464.203c-121.597 0-220.193-98.597-220.193-220.203 0-121.617 98.597-220.192 220.193-220.192 121.627 0 220.213 98.576 220.213 220.192 0 121.606-98.586 220.203-220.213 220.203zm-83.08-331.23l221.19 111.03-221.19 111.025V144.972z'/></g></defs><use x='0' y='0' xlink:href='#play' fill='black' fill-opacity='0.4' filter='url(#blur)'/><use x='0' y='0' xlink:href='#play' fill='white'/></svg>");
        content: ' ';
        display: block;

        width: 30%;
        height: 0;
        padding-bottom: 30%;

        position: absolute;
        top: 50%;
        left: 50%;

        margin-top: -15%;
        margin-left: -15%;
      }

      &:hover::after {
        @include opacity.opacity(0.99);
        transform: scale(1);
      }
    }

    .thumb--medallions {
      width: 132px;
      height: 99px;
    }
  }
}
