/**
 * Mixins to control icon output
 */
@use 'inline-block';

@mixin icon-size($size: 40px) {
  width: $size;
  height: $size;
  line-height: ($size + 1px); // a bit of room for IE rendering
  font-size: $size;
}

@mixin icon() {
  @include inline-block.inline-block;

  font-family: icons;
  font-style: normal;
  font-weight: normal;

  // Important that the icon is centered when sized
  text-align: center;
  vertical-align: middle;

  // Suppress from screenreaders that support the speak property
  speak: none;

  // Standard webkit font rendering is better for icon fonts
  -webkit-font-smoothing: antialiased;

  // Improve Firefox icon font rendering in a certain OS...
  -moz-osx-font-smoothing: grayscale;
}
