/**
 * An inline block with Old IE compatibility.
 */

@mixin inline-block($alignment: middle) {
  display: inline-block;

  // Behavior inherited from Compass
  vertical-align: $alignment;
}
