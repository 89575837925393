/**
 * Support for color inversions where the .inverse CSS class
 * is a parent.
 *
 * Note that "invert" / "inverse" should be read as "flip from
 * dark-on-white to white-on-dark" (i.e., never the other way
 * around). If you want to go the other way, start out with the
 * "inverse" class applied and then remove it.
 */

@function invertColor($color) {
  // This was a neat idea, but the reality of the matter seems to be
  // that anything less than brilliant white is too weak for use
  // against an inverted background.
  // @return lighten(invert($color), 30%)

  @return #fff;
}

@mixin color($color) {
  color: $color;

  .inverse & {
    color: invertColor($color);
  }
}
