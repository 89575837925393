/**
 * Important global variables and configuration.
 */

/*
 * Colors
 */

$ninja-black: rgba(0, 0, 0, 0);
$natural-black: #111111;
$darker-gray: #333333;
$dark-gray: #666666;
$gray: #999999;
$light-gray: #cccccc;
$lighter-gray: #f6f6f6;
$white-d: #e3e3e3;
$white: #ffffff;
$error-red: #bf0c00;
$orange: #ff904b;
$coral: #ff5041;
$blue: #0698d0;

/*
* The official screen and print
* versions of "TED red" are:
* * #E62B1E
* * Pantone 485 C
* * C=0 M=100 Y=100 K=0
* * R=230 G=43 B=30
*/
$ted-red: #e62b1e;

// Brand Colors
//$twitter: $bc-twitter;
//$facebook: $bc-facebook;
$messenger: #0084ff;
//$linkedin: $bc-linkedin;
//$reddit: $bc-reddit;
//$whatsapp: $bc-whatsapp;

// Gradients
$ted-red-grad: linear-gradient($ted-red, #cf271b);
$light-grad: linear-gradient($white, #f2f2f2);
$med-grad: linear-gradient(#f2f2f2, #e6e6e6);
$dark-grad: linear-gradient(#393636, #262626);

// Applied colors
$body-bg: #f0f0f0; // applied to the <body> tag
$main-bg: #ffffff; // actual site background
$text-color: #333333;
$link-color: #333333;
$link-hover-color: #333333;
$form-input-highlight: #a8cbec;
$outline: #73afec;
$alert-info-bg: #cfeaf5;
$alert-success-bg: #cfeaf5;
$alert-warning-bg: #fcf1cc;
$alert-danger-bg: #f2d2cf;

// Explore / TED Recommends gradients
$explore-grad-blue-x: linear-gradient(120deg, #c3f7fd 0%, #f4f4ea 60%);
$explore-grad-blue-rev-x: linear-gradient(120deg, #f4f4ea, #c3f7fd);

$theme_colors: (
  blue: $blue,
  black: $natural-black,
  gray-dd: $darker-gray,
  gray-d: $dark-gray,
  gray: $gray,
  gray-l: $light-gray,
  gray-ll: $lighter-gray,
  white-d: $white-d,
  white: $white,
  orange: $orange,
  coral: $coral,
  red: $ted-red,
  //twitter: $twitter,
  //facebook: $facebook,
  //linkedin: $linkedin,
  messenger: $messenger,
  //reddit: $reddit,
  //sms: $whatsapp,
  //whatsapp: $whatsapp,
  none: transparent
);

$ms-base: 1rem;
//$ms-ratio: $minor-third;

$mq-xxs: 400px;
$mq-xs: 500px;
$mq-sm: 700px;
$mq-md: 900px;
$mq-lg: 1100px;
$mq-xl: 1300px;
$mq-xxl: 1700px;

$mq-xxs-min: 'min-width: #{$mq-xxs}';
$mq-xs-min: 'min-width: #{$mq-xs}';
$mq-sm-min: 'min-width: #{$mq-sm}';
$mq-md-min: 'min-width: #{$mq-md}';
$mq-lg-min: 'min-width: #{$mq-lg}';
$mq-xl-min: 'min-width: #{$mq-xl}';
$mq-xxl-min: 'min-width: #{$mq-xxl}';

$mq-xxs-max: 'max-width: #{$mq-xxs}';
$mq-xs-max: 'max-width: #{$mq-xs}';
$mq-sm-max: 'max-width: #{$mq-sm}';
$mq-md-max: 'max-width: #{$mq-md}';
$mq-lg-max: 'max-width: #{$mq-lg}';
$mq-xl-max: 'max-width: #{$mq-xl}';
$mq-xxl-max: 'max-width: #{$mq-xxl}';

$breaks: (
  n: 'none',
  xxs: $mq-xxs-min,
  xs: $mq-xs-min,
  sm: $mq-sm-min,
  md: $mq-md-min,
  lg: $mq-lg-min,
  xl: $mq-xl-min,
  xxl: $mq-xxl-min
);

$size-none: 0;
//$size-xxs: ms(-3);
//$size-xs: ms(-2);
//$size-sm: ms(0);
//$size-md: ms(1);
//$size-lg: ms(3);
//$size-xl: ms(4);
//$size-xxl: ms(5);

/*
 * Grid system
 * --------------------------------------------------
 */

// Distance between the grid and the page margin
$grid-margin-base: 20px;
$grid-margin-large: 40px;
// Distance between grid columns
$grid-gutter-base: 40px;

/*
 * Typography
 * --------------------------------------------------
 */

// Font size and line height controls
$typical-font-size: 14px;
$typical-line-height: 1.5;

// Typographic variables
$font-family-sans-serif:
  'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-system: 'Helvetica Neue', Helvetica, Arial, sans-serif;

/*
 * Typographic weights; use these instead of "normal", "bold", etc.
 * These are set up per their W3 keyword synonyms:
 * http://www.w3.org/TR/CSS2/fonts.html#font-boldness
 */
$neue-light: 200;
$neue-roman: 400;
$neue-bold: 700;
$neue-heavy: 900;

/*
 * Miscellaneous
 * --------------------------------------------------
 */

// Height used for form controls (input fields, dropdowns, etc.)
$form-control-height: 40px;

/*
 * Used with the exports system to skip global blocks and thus
 * avoid redundancy between global.css and other CSS files
 */
$skip-globals: true !default;
$skip-globals-complete: false !default;

// A base for z-indexes to make sure they're higher than our ads
$z-index-base: 1000001;

/*
 * IE compatibility system
 * --------------------------------------------------
 */

/*
 * If false, media queries will be omitted and styles will be
 * included disregarding breakpoint (for Aulde IE support)
 */
$respond-to-queries: true !default;

/*
 * List of breakpoints to recognize. Modify this list to only
 * include certain breakpoints (e.g., to split CSS into multiple
 * files for performance.)
 */
$respond-to-breakpoints:
  0, 320px, 480px, 600px, 768px, 1024px, 1200px, 1500px !default;

/*
 * List of CSS classes to blacklist when content blocks are used
 * with the react-to mixin
 */
$react-to-blacklist: 'oldie', 'ie6', 'ie7', 'ie8' !default;
