/**
 * Mixins to control font size
 */

// Returns rem version of a pixel-based font size,
// assuming the typical -root- font size of 16px
@use 'sass:math';

@function calculateRem($size) {
  $rem-size: math.div($size, 16px);
  @return #{$rem-size}rem;
}

@mixin font-size($size: 16px) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin font-line($font-size: 16px, $line-height: 20px) {
  @include font-size($font-size);
  line-height: math.div($line-height, $font-size);
}
