/**
 * This mixin is an essential component of our mobile-first strategy.
 * It produces a media query and / or output depending on the
 * targeted breakpoint and the include_media_* variables defined at
 * compile time. Thus it's possible, through configuration alone, to
 * exclude specific media queries or breakpoints, making IE support
 * and performance-related file slicing a matter of configuration.
 */

@use 'sass:list';
@use 'sass:map';

// Import variables
@use 'variables';

@mixin respond-to($size: 0) {
  @if variables.$respond-to-queries {
    @if $size == 0 {
      @content;
    } @else {
      @media screen and (min-width: $size) {
        @content;
      }
    }
  } @else {
    @content;
  }
}
