@use 'exports';
@use 'respond-to';

/**
 * Standard item list.
 */

@include exports.exports('list') {
  @include respond-to.respond-to {
    .list {
      margin: 20px 0;
      padding: 0;
    }

    .list__item {
      border-top: 1px dotted #cccccc;
      margin-top: 20px;
      padding-top: 20px;

      &:first-child {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}
