@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin container {
  @include clearfix;
  clear: both;
  float: none;
  margin: 0 auto;
  max-width: 1240px;
}
