@use 'variables';
@use 'respond-to';

.breadcrumb,
.breadcrumb__link {
  color: variables.$gray;
}

.breadcrumb {
  font-size: 12px;
  margin: 0;
  padding: 10px 0;

  &__link {
    text-decoration: none;

    &:hover {
      color: variables.$dark-gray;
    }
  }

  &__separator {
    margin: 0 5px;
  }
}
