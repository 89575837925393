/**
 * Typographic styles
 */
@use 'sass:math';

@use 'respond-to';
@use 'color';
@use 'font-size';
@use 'variables';

@include respond-to.respond-to {
  // Older pages on ted.com rely on a font size of ~14px/85% as part
  // of the cascade. Here it's being added to the .page element so as
  // to keep the root itself clean
  .page {
    font-size: #{(math.div(variables.$typical-font-size, 16px) * 100) + '%'};
    line-height: variables.$typical-line-height;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .h7,
  .h8,
  .h9,
  .h10,
  .h11,
  .h12,
  .h13 {
    @include color.color(#222222);
    font-size: 100%;
    font-weight: variables.$neue-bold;
    line-height: 1.2;
    margin: 0;

    a {
      @include color.color(#111111);
      text-decoration: none;

      &:hover {
        @include color.color(#555555);
      }
    }

    a:hover & {
      @include color.color(#555555);
    }
  }

  .h1,
  .h2 {
    letter-spacing: -0.03em;
    @include font-size.font-size(38px);
  }

  .h1--alt,
  .h1__alt {
    letter-spacing: -0.03em;
    font-weight: variables.$neue-light;
  }

  .h2 {
    font-weight: variables.$neue-heavy;
    letter-spacing: -1px;
  }

  .h3 {
    @include font-size.font-size(32px);
    font-weight: variables.$neue-light;
  }

  .h4 {
    @include font-size.font-size(28px);
    font-weight: variables.$neue-light;
  }

  .h5 {
    @include font-size.font-size(24px);
    font-weight: variables.$neue-light;
  }

  .h6 {
    @include font-size.font-size(24px);
    font-weight: variables.$neue-bold;
  }

  .h7 {
    @include font-size.font-size(18px);
    font-weight: variables.$neue-bold;
  }

  .h8 {
    @include font-size.font-size(18px);
  }

  .h8__alt {
    font-weight: variables.$neue-light;
  }

  .h9 {
    @include font-size.font-size(16px);
    font-weight: variables.$neue-heavy;
  }

  .h10 {
    @include font-size.font-size(16px);
  }

  .h11 {
    @include font-size.font-size(16px);
    font-weight: variables.$neue-roman;
  }

  .h12 {
    @include font-size.font-size(16px);
    color: #222222;
    font-family: variables.$font-family-serif;
    font-style: italic;
    font-weight: variables.$neue-roman;
  }

  .h13 {
    font-weight: variables.$neue-roman;
    text-transform: uppercase;
  }

  .p1 {
    @include font-size.font-line(22px, 35px);
    font-weight: variables.$neue-light;
    letter-spacing: -0.03em;
  }

  .p2 {
    @include font-size.font-line(18px, 25px);
  }

  .p3 {
    @include color.color(#666666);
    @include font-size.font-line(15px, 25px);
  }

  .p4 {
    @include color.color(#666666);
    @include font-size.font-line(variables.$typical-font-size, 25px);
  }

  /*
   * Links
   */
  .l1,
  .l2,
  .l3 {
    @include color.color(#333333);
    font-weight: variables.$neue-bold;

    &:hover {
      @include color.color(#666666);
    }
  }

  .l1 {
    @include font-size.font-size(16px);
  }

  .l2 {
    @include font-size.font-size(15px);
  }

  /*
   * Miscellaneous typographic styles
   */
  .bq1,
  .bq2,
  .c1,
  .e1 {
    font-family: variables.$font-family-serif;
    font-style: italic;
  }

  .c1,
  .e1,
  .e2,
  .meta {
    @include color.color(#666666);
  }

  .bq1,
  .bq2 {
    @include color.color(#333333);
    position: relative;

    &::after {
      content: '\201D';
    }

    &::before {
      content: '\201C';
      width: 2ex;
      position: absolute;
      left: -1ex;
    }
  }

  .bq1 {
    @include font-size.font-line(28px, 45px);

    &::before {
      color: #cccccc;
      font-size: 270%;
      line-height: 1.1;
      width: 2em;
      left: -1.3ex;
    }
  }

  .bq2 {
    @include font-size.font-line(20px, 28px);
  }

  .c1 {
    @include font-size.font-line(15px, 22px);
  }

  .e1 {
    @include font-size.font-line(16px, 25px);
    font-weight: normal;
  }

  .e2 {
    @include font-size.font-line(12px, 20px);
    font-weight: bold;
    text-transform: uppercase;
  }

  .meta {
    @include font-size.font-size(14px);
    font-weight: variables.$neue-bold;
  }

  .meta__item {
    &::before {
      content: '\2022 ';
    }

    &:first-child::before {
      display: none;
    }
  }

  .meta__row {
    display: block;
  }

  .meta__val {
    font-weight: variables.$neue-roman;
  }

  .small {
    @include font-size.font-line(12px, 18px);
  }

  .undec {
    text-decoration: none;
  }

  /*
   * Contextual colors
   */
  .text-danger,
  .text-danger a {
    @include color.color(variables.$ted-red);
  }

  .text-primary,
  .text-primary a {
    @include color.color(variables.$natural-black);
  }

  .text-secondary,
  .text-secondary a {
    @include color.color(variables.$gray);
    font-weight: variables.$neue-roman;
  }

  /*
   * Bottom margins
   */
  .m1 {
    margin-bottom: 40px;
  }

  .m2 {
    margin-bottom: 30px;
  }

  .m3 {
    margin-bottom: 20px;
  }

  .m4 {
    margin-bottom: 10px;
  }

  .m5 {
    margin-bottom: 5px;
  }

  /*
   * Top margins
   */

  .mt5 {
    margin-bottom: 5px;
  }

  .font-smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .font-weight--light {
    font-weight: variables.$neue-light;
  }

  .font-weight--roman {
    font-weight: variables.$neue-roman;
  }

  .font-weight--bold {
    font-weight: variables.$neue-bold;
  }

  .font-weight--heavy {
    font-weight: variables.$neue-heavy;
  }

  .line-height {
    line-height: 1;
  }

  .line-height--title {
    line-height: 1.2;
  }

  .line-height--copy {
    line-height: 1.5;
  }

  .tracked {
    letter-spacing: 0.16em;
  }

  .tracked--tight {
    letter-spacing: -0.03125em;
  }

  .tracked--loose {
    letter-spacing: -0.32em;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-center {
    text-align: center;
  }

  .text-justify {
    text-align: justify;
  }

  .text-table {
    text-align: table;
  }

  .text-bottom {
    vertical-align: bottom;
  }

  .text-top {
    vertical-align: top;
  }

  .text-middle {
    vertical-align: middle;
  }
}

@include respond-to.respond-to(1024px) {
  .h1,
  .h2 {
    @include font-size.font-line(42px, 44px);
  }

  .h3 {
    @include font-size.font-size(32px);
  }

  .h9 {
    @include font-size.font-size(15px);
  }

  .h10 {
    @include font-size.font-size(15px);
  }

  .h11 {
    @include font-size.font-size(15px);
  }

  .h12 {
    @include font-size.font-size(15px);
  }

  .p1 {
    @include font-size.font-line(22px, 35px);
  }

  .p3 {
    @include font-size.font-line(14px, 20px);
  }

  .p4 {
    @include font-size.font-line(13px, 20px);
  }

  /*
   * Links
   */
  .l1 {
    @include font-size.font-size(14px);
  }

  .l2 {
    @include font-size.font-size(13px);
  }

  /*
   * Miscellaneous typographic styles
   */
  .bq1 {
    @include font-size.font-line(28px, 45px);
  }

  .c1 {
    @include font-size.font-line(12px, 20px);
  }

  .e1 {
    @include font-size.font-size(15px);
  }

  .e2 {
    @include font-size.font-line(10px, 20px);
  }

  .meta {
    @include font-size.font-size(12px);
  }
}
