/**
 * Creates a banner with text on the left and an image on the right.
 */
@use 'exports';
@use 'respond-to';
@use 'variables';

@include exports.exports('hero') {
  @include respond-to.respond-to {
    .hero {
      background-color: #000000;
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .hero__content {
      padding: 10px;
    }

    .hero__image {
      background-size: 100% auto;
      background-position: right 0;
      background-repeat: no-repeat;
      width: 100%;
      padding-top: 56.25%;
    }
  }
  @include respond-to.respond-to(600px) {
    .hero__content {
      float: left;
      position: relative;
      width: 90%;
    }

    .hero__image {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 66.6%;
      padding-top: 0;

      &::after {
        background-image: linear-gradient(
          to right,
          #000000,
          variables.$ninja-black
        );
        content: ' ';
        display: block;
        position: absolute;
        left: -1px;
        top: 0;
        width: 80%;
        height: 100%;
      }
    }

    .hero__spacer {
      float: right;
      overflow: visible;
      width: 1%;
    }

    .hero__spacer__inner {
      height: 0;
      padding-bottom: 3750%;
      position: relative;
      width: 10000%;
    }
  }
  @include respond-to.respond-to(768px) {
    .hero__content {
      width: 70%;
      padding: 20px;
    }

    .hero__image {
      &::after {
        width: 60%;
      }
    }
  }
}
