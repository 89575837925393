@use 'sass:math';

@use 'clearfix';
@use 'respond-to';
@use 'variables';

/**
 * Grid system
 *
 * See /swatch/grid for examples.
 */

@include respond-to.respond-to {
  .row {
    @include clearfix.clearfix;
    margin-left: 0 - variables.$grid-gutter-base;
  }

  .col,
  .col-oh-1,
  .col-oh-2,
  .col-oh-3,
  .col-oh-4,
  .col-oh-5,
  .col-oh-6,
  .col-oh-7,
  .col-oh-8,
  .col-oh-9,
  .col-oh-10,
  .col-oh-11,
  .col-oh-12,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    // border: 0 solid transparent;
    padding-left: variables.$grid-gutter-base;
    float: left;
    width: 100%;

    // Remove top margins of the first items in each column to make
    // column tops line up with one another
    & > *:first-child,
    & > *:first-child > *:first-child,
    & > *:first-child > *:first-child > *:first-child {
      margin-top: 0;
    }
  }

  // Skinny rows have small gutters
  .row-skinny {
    margin-left: 0 - variables.$grid-margin-base;

    .col {
      padding-left: variables.$grid-margin-base;
    }
  }

  .row-gutterless {
    margin-left: 0;

    [class*=' col'],
    [class^='col'] {
      padding-left: 0;
    }
  }

  .row--equal-height {
    display: flex !important;
    flex-flow: row wrap;
  }

  .col--equal-height {
    display: flex;
  }

  .flex1 {
    flex: 1;
  }

  // Smallest column sizes
  @for $i from 1 through 12 {
    .col-oh-#{$i} {
      width: math.div(100%, math.div(12, $i));
    }
  }

  // Forms the alternative %up grid
  @for $i from 2 through 6 {
    .row-oh-#{$i}up {
      .col {
        width: math.div(100%, $i);
      }

      .col:nth-child(1n) {
        clear: none;
      }

      .col:nth-child(#{$i}n + 1) {
        clear: left;
      }
    }
  }
}

@include respond-to.respond-to(480px) {
  .row-xs-wide {
    margin-left: 0 - variables.$grid-gutter-base;

    .col {
      padding-left: variables.$grid-gutter-base;
    }
  }

  .row-xs--equal-height {
    display: flex;
  }

  .col-xs--equal-height {
    display: flex;
  }
  @for $i from 1 through 12 {
    .col-xs-#{$i} {
      width: math.div(100%, math.div(12, $i));
    }
  }
  @for $i from 2 through 6 {
    .row-xs-#{$i}up {
      .col {
        width: math.div(100%, $i);
      }

      .col:nth-child(1n) {
        clear: none;
      }

      .col:nth-child(#{$i}n + 1) {
        clear: left;
      }
    }
  }
}

@include respond-to.respond-to(768px) {
  .row-sm-wide {
    margin-left: 0 - variables.$grid-gutter-base;

    .col {
      padding-left: variables.$grid-gutter-base;
    }
  }

  .row-sm--equal-height {
    display: flex;
  }

  .col-sm--equal-height {
    display: flex;
  }
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      width: math.div(100%, math.div(12, $i));
    }
  }
  @for $i from 0 through 12 {
    .col-sm-offset-#{$i} {
      margin-left: math.div(100%, 12) * $i;
    }
  }
  @for $i from 2 through 6 {
    .row-sm-#{$i}up {
      .col {
        width: math.div(100%, $i);
      }

      .col:nth-child(1n) {
        clear: none;
      }

      .col:nth-child(#{$i}n + 1) {
        clear: left;
      }
    }
  }
}

@include respond-to.respond-to(1024px) {
  .row-lg-wide {
    margin-left: 0 - variables.$grid-gutter-base;

    .col {
      padding-left: variables.$grid-gutter-base;
    }
  }

  .row-lg--equal-height {
    display: flex;
  }

  .col-lg--equal-height {
    display: flex;
  }

  .col--delineated-lg {
    border-left: 1px solid #e5e5e5;
  }
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      width: math.div(100%, math.div(12, $i));
    }
  }
  @for $i from 0 through 12 {
    .col-lg-offset-#{$i} {
      margin-left: math.div(100%, 12) * $i;
    }
  }
  @for $i from 2 through 6 {
    .row-lg-#{$i}up {
      .col {
        width: math.div(100%, $i);
      }

      .col:nth-child(1n) {
        clear: none;
      }

      .col:nth-child(#{$i}n + 1) {
        clear: left;
      }
    }
  }

  /*
   * Adds a sidebar with an enforced width of 300px,
   * necessary for ads
   */
  .col-lg-sidebar {
    width: 300px + variables.$grid-gutter-base;
  }

  .col-lg-sidebarred {
    margin-right: 0 - (300px + variables.$grid-gutter-base);
    padding-right: 300px + variables.$grid-gutter-base;
  }
}
